import Head from "next/head";
import * as React from "react";

import { ssrMode } from "@app/constants";

import { Consumer as MetaConsumer } from "./context";

function truncateMetaDescription(metaDescription, maxLength = 155) {
  if (metaDescription.length > maxLength) {
    // Trim the string to the maximum length and add "..." at the end
    return `${metaDescription.substring(0, maxLength - 3)}...`;
  }
  // Return the original string if it's within the limit
  return metaDescription;
}

const Consumer: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <MetaConsumer>
    {({
      title,
      description,
      image,
      type,
      url,
      canonicalUrl,
      language,
      marketplaces,
    }) => (
      <>
        <Head>
          <title>{title}</title>
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          {language && <meta name="locale" content={language} />}
          {marketplaces &&
            marketplaces.length > 0 &&
            marketplaces.map((marketplace, index) => (
              <link
                key={index}
                rel="alternate"
                hrefLang={marketplace.language}
                href={marketplace.siteUrl}
              />
            ))}
          <meta
            name="description"
            content={truncateMetaDescription(description)}
          />
          <meta
            key="og:description"
            property="og:description"
            content={truncateMetaDescription(description)}
          />
          <meta
            key="og:url"
            property="og:url"
            content={
              url || ssrMode ? global.location.href : window.location.href
            }
          />
          <meta key="og:title" property="og:title" content={title} />
          <meta key="og:type" property="og:type" content={type} />
          {!!image && (
            <meta key="og:image" property="og:image" content={image} />
          )}
        </Head>
        {children}
      </>
    )}
  </MetaConsumer>
);

export default Consumer;
