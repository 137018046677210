import { Box, Collapse, ScaleFade } from "@chakra-ui/react";
import { useAuth } from "@the-volte/svc-core-sdk";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import Media from "react-media";

import { ssrMode } from "@app/constants";
import {
  LogoLink,
  MainMenuLeft,
  MainMenuRight,
  MobileNavSearch,
} from "@components/atoms";
import { MobileNavSearchOverlayBG } from "@components/atoms/MobileNavSearch/MobileNavSearchOverlayBG";
import { MobileNavSearchOverlayFG } from "@components/atoms/MobileNavSearch/MobileNavSearchOverlayFG";
import { OverlayContext, OverlayType } from "@components/legacy";
import { MenuContext } from "@components/legacy/MenuProvider/context";
import { MenuItem } from "@graphql/gqlTypes/MenuItem";
import { navMenuXLHeight, xLargeScreen } from "@styles/constants";

import { MainMenuExpandableSection } from "../../molecules";

interface IMenuItem extends MenuItem {
  children?: MenuItem[];
}

interface MainMenuProps {
  login: () => Promise<void>;
  handleSignOut: () => void;
  loading: boolean;
}

export const MainMenu: React.FC<MainMenuProps> = ({
  login,
  handleSignOut,
  loading,
}) => {
  const router = useRouter();
  const { mainMenu } = useContext(MenuContext);
  const { user } = useAuth();
  const overlay = React.useContext(OverlayContext);
  const isSearchInputOpen = overlay.type === OverlayType.search;

  const [isPinned, setIsPinned] = useState(true);
  const [activeMenu, setActiveMenu] = useState<IMenuItem | null>(null);

  if (!mainMenu) {
    return null;
  }

  const resetActiveMenu = () => {
    setActiveMenu(null);
  };
  useEffect(() => {
    const handleScrollChange = () => {
      if (router.pathname !== "/") {
        setIsPinned(true);
        return;
      }

      if (window.scrollY === 0) {
        setIsPinned(true);
      } else {
        setIsPinned(false);
      }
    };
    window.addEventListener("scroll", handleScrollChange);

    return () => {
      window.removeEventListener("scroll", handleScrollChange);
    };
  }, [router.pathname]);

  return (
    <Box
      id="header"
      position="sticky"
      zIndex={1000}
      top={0}
      display="flex"
      alignItems="center"
      flex="none"
      flexDirection="column"
      width={{ xl: "100%" }}
      color="black"
      backgroundColor="primary"
      onMouseLeave={resetActiveMenu}
    >
      {/* Nav Search  */}
      <Box
        position="relative"
        width="100%"
        top={{ base: 0, xl: navMenuXLHeight }}
      >
        <ScaleFade in={isSearchInputOpen} unmountOnExit>
          <MobileNavSearchOverlayFG>
            <MobileNavSearch hideNav={overlay.hide} />
          </MobileNavSearchOverlayFG>
        </ScaleFade>

        {isSearchInputOpen && (
          <MobileNavSearchOverlayBG closeSearchHandler={overlay.hide} />
        )}
      </Box>

      <Box width="100%">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          position="relative"
          paddingX={{ base: "20px", xl: "1.75rem" }}
          justifyContent="space-between"
          paddingTop={{ base: "0.938rem", xl: 0 }}
          paddingBottom={{ base: "0.6rem", xl: 0 }}
          width="100%"
          minHeight={{ xl: navMenuXLHeight }}
        >
          <MainMenuLeft
            loading={loading}
            menu={mainMenu}
            login={login}
            user={user}
          />
          <Box
            display="flex"
            justifyContent="center"
            flex={{ base: "1 1 0px", xl: "auto" }}
            marginBottom={{ base: "auto", xl: 0 }}
          >
            <Media
              query={{ maxWidth: xLargeScreen - 1 }}
              defaultMatches={ssrMode} // SSR for Mobile
              render={() => <LogoLink />}
            />

            {/* Base Desktop Navbar */}
            {/* Fixes The Volte Logo to where the navbar is normally when scrolling down */}
            <Media
              query={{ minWidth: xLargeScreen }}
              defaultMatches={ssrMode} // SSR for Mobile
              render={() =>
                isPinned ? (
                  <MainMenuExpandableSection mainMenu={mainMenu} />
                ) : (
                  <LogoLink />
                )
              }
            />
          </Box>
          <MainMenuRight
            menu={mainMenu}
            loading={loading}
            user={user}
            handleSignOut={handleSignOut}
            login={login}
            resetActiveMenu={resetActiveMenu}
          />
        </Box>

        {/* When scrolling down, the navbar is moved under the The Volte Logo  */}
        <Media
          query={{ minWidth: xLargeScreen }}
          defaultMatches={ssrMode} // SSR for Mobile
          render={() =>
            !isPinned && (
              <Box w="full" position="absolute" bgColor="white">
                <Box
                  display="flex"
                  justifyContent="center"
                  marginBottom={{ base: "auto", xl: 0 }}
                  borderTop="0.5px solid gray"
                  borderBottom="0.5px solid gray"
                >
                  <MainMenuExpandableSection mainMenu={mainMenu} lower />
                </Box>
              </Box>
            )
          }
        />

        <Collapse animateOpacity in={isPinned && !!activeMenu}>
          <Box w="full" position="absolute" bgColor="secondary" p="2.5rem 6rem">
            <MainMenuExpandableSection mainMenu={mainMenu} />
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
