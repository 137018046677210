import * as React from "react";

import S from "./styles";

interface Medium {
  ariaLabel: string;
  path: string;
  href: string;
}

interface IconProps extends React.HTMLProps<HTMLAnchorElement> {
  medium: Medium;
  target?: string;
}

const SocialMediaIcon: React.FC<IconProps> = ({ medium, target }) => (
  <a
    href={medium.href}
    target={target || "_blank"}
    aria-label={medium.ariaLabel}
  >
    <S.Icon src={medium.path.default.src} />
  </a>
);

export default SocialMediaIcon;
