/* eslint-disable global-require */
import { ssrMode } from "@temp/constants";

export const PROVIDERS = {
  DUMMY: {
    label: "Dummy",
  },
  STRIPE: {
    label: "Stripe",
  },
};

export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/thevolte/",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/thevolte/",
    path: require("../images/instagram-icon.svg"),
  },
  {
    ariaLabel: "twitter",
    href: "https://twitter.com/the_volte",
    path: require("../images/twitter-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description:
    "Dress hire grows your wardrobe with over 20,000 new, chic designer options. We help women get the perfect dress for every event on their calendar.",
  image: "",
  title: "Dress Hire | Australia's Largest Dress Hire Selection",
  type: "website",
  url: !ssrMode ? window.location.origin : "",
};
