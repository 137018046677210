import { Box } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { ReactNode } from "react";

import {
  navMenuSearchMobileHeight,
  navMenuSearchXLHeight,
} from "@styles/constants";

type MobileNavSearchOverlayBGProps = {
  children?: ReactNode;
  closeSearchHandler: (e: unknown) => void;
};

export const MobileNavSearchOverlayBG = ({
  children,
  closeSearchHandler,
}: MobileNavSearchOverlayBGProps) => {
  const theme = useTheme();

  return (
    <Box
      height="100vh"
      width="100vw"
      maxW="100%"
      zIndex={1010}
      bgColor={theme.colors.overlay}
      position="absolute"
      top={{
        base: navMenuSearchMobileHeight,
        xl: navMenuSearchXLHeight,
      }}
      onClick={closeSearchHandler}
    >
      {children}
    </Box>
  );
};
