import { Fade } from "@chakra-ui/react";
import * as React from "react";

import { MobileNav } from "../../organisms";
import { Overlay, OverlayContext, OverlayType } from "..";
import Cart from "./Cart";
import Modal from "./Modal";
import Notification from "./Notification";

const renderMobileNav = (overlay, login, handleSignOut) => {
  switch (overlay.type) {
    case OverlayType.mobileAccount:
      return (
        <MobileNav
          showAccount
          login={login}
          signOut={handleSignOut}
          hideNav={overlay.hide}
        />
      );
    case OverlayType.mobileMainMenu:
      return (
        <MobileNav
          showMainMenu
          login={login}
          signOut={handleSignOut}
          hideNav={overlay.hide}
          menuItems={overlay.context.data}
        />
      );

    default:
      return null;
  }
};

interface OverlayManagerProps {
  login: () => Promise<void>;
  handleSignOut: () => void;
}

const OverlayManager: React.FC<OverlayManagerProps> = ({
  login,
  handleSignOut,
}) => {
  const overlay = React.useContext(OverlayContext);

  if (
    overlay.type === OverlayType.mobileAccount ||
    overlay.type === OverlayType.mobileMainMenu ||
    overlay.type === null
  ) {
    return (
      <Fade in={!!overlay.type} unmountOnExit style={{ zIndex: 1001 }}>
        {renderMobileNav(overlay, login, handleSignOut)}
      </Fade>
    );
  }

  switch (overlay.type) {
    case OverlayType.modal:
      return <Modal testingContext="modal" overlay={overlay} />;

    case OverlayType.message:
      return <Notification overlay={overlay} />;

    case OverlayType.cart:
      return <Cart overlay={overlay} />;

    case OverlayType.mainMenuNav:
      return <Overlay testingContext="mainMenuOverlay" context={overlay} />;

    default:
      return null;
  }
};

export default OverlayManager;
