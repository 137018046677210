import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

type MobileNavSearchOverlayFGProps = {
  children: ReactNode;
};

export const MobileNavSearchOverlayFG = ({
  children,
}: MobileNavSearchOverlayFGProps) => (
  <Box
    width="100%"
    zIndex={1020}
    bgColor="tertiary"
    p={["0.7rem", null, null, "1.5rem"]}
    position="absolute"
  >
    {children}
  </Box>
);
