import * as React from "react";

export enum OverlayType {
  cart = "cart",
  checkout = "checkout",
  login = "login",
  message = "message",
  sideNav = "side-nav",
  password = "password",
  search = "search",
  mainMenuNav = "main-menu-nav",
  modal = "modal",
  register = "register",
  mobileAccount = "mobile-account",
  mobileMainMenu = "mobile-main-menu",
}

export enum OverlayTheme {
  left = "left",
  right = "right",
  modal = "modal",
}

export interface InnerOverlayContextInterface {
  title?: string;
  content?: string | React.ReactNode;
  status?: "success" | "error";
  data?: any;
}

type ShowOverlayType = (
  type: OverlayType,
  theme?: OverlayTheme,
  context?: InnerOverlayContextInterface
) => void;

export interface OverlayContextInterface {
  type: OverlayType | null;
  theme: OverlayTheme | null;
  context: InnerOverlayContextInterface;
  show: ShowOverlayType;
  hide(): void;
  toggle: ShowOverlayType;
}

export const OverlayContext = React.createContext<OverlayContextInterface>({
  context: null,
  hide: () => {},
  show: (type) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  toggle: (type) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  theme: null,
  type: null,
});

OverlayContext.displayName = "OverlayContext";
