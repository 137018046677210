import styled from "@emotion/styled";

import { media, minMedia } from "@styles";

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 1rem;
  margin: auto;

  ${media.smallScreen`
    justify-content: space-around;
  `}

  ${minMedia.mediumScreen`
    justify-content: space-around;
    max-width: 75rem;
  `};

  ${minMedia.xLargeScreen`
    max-width: 75rem;
  `};
`;
