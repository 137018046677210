import styled from "@emotion/styled";

import {
  baseFontColor,
  boldFontWeight,
  gray,
  mediumScreen,
  smallScreen,
  spacer,
  white,
} from "@styles/constants";

const CartTable = styled.table`
  @media (max-width: ${mediumScreen}) {
    td:last-child,
    th:last-child {
      text-align: right;
    }
  }

  .row--processing td {
    position: relative;

    &::after {
      background-color: rgba(${white}, 0.65);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .cost {
    color: ${baseFontColor};
    font-weight: ${boldFontWeight};
    text-transform: uppercase;
  }

  .quantity-header {
    text-align: center;
    padding: 0;
  }

  .quantity-cell {
    padding: 0;
    text-align: center;

    div {
      align-items: center;
      display: flex;
      justify-content: space-around;

      @media (max-width: ${smallScreen}) {
        flex-direction: column-reverse;
      }

      &.disabled {
        opacity: 0.8;

        &:hover svg {
          cursor: default;
        }
      }
    }

    & > div {
      padding: ${spacer / 2}rem;
    }
  }

  svg:hover {
    cursor: pointer;
  }

  .thumbnail {
    display: inline-block;
    height: 100px;
    width: 70px;
    margin-right: 10px;
    position: relative;
  }

  .product-row {
    display: flex;
    align-items: center;
  }

  .checkout-action {
    text-align: right;
    margin: 0 ${spacer * 2}rem ${spacer * 3}rem 0;

    @media (max-width: ${smallScreen}) {
      text-align: center;
    }
  }

  .empty {
    text-align: center;
    padding: ${spacer * 5}rem 0;

    h4 {
      font-weight: ${boldFontWeight};
      text-transform: uppercase;
      margin-bottom: ${spacer}rem;
    }

    p {
      color: ${gray};
    }

    &__action {
      text-align: center;
      margin-top: ${spacer}rem;
    }
  }
`;

export default { CartTable };
