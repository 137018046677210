import { Box, Center, Spinner, useMediaQuery } from "@chakra-ui/react";
import { useAuth } from "@the-volte/svc-core-sdk";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ReactWindowScroller } from "react-window-scroller";

import { ssrMode } from "@app/constants";
import { Button, Loader } from "@components/atoms";
import { GoToTopButton } from "@components/atoms/GoToTop";
import { ProductTile } from "@components/molecules";
import { useLocalStorageQuiet } from "@hooks/useLocalStorage";
import { accentBlue, breakpoints } from "@styles/constants";
import { IProduct } from "@types";
import { uniqueByKey } from "@utils/arrays";

import * as S from "./styles";
import { IProps } from "./types";

type ProductType = IProduct & { inWishlist: boolean };

export const ProductList: React.FC<IProps> = ({
  products,
  canLoadMore = false,
  loading = false,
  onLoadMore = () => null,
  showGoToTop = true,
  numberOfProducts,
  loadMoreError,
  infiniteScrollingMode,
  deliveryFilter,
  hasCollectionSale,
  isWarehouseSaleCollection,
  warehouseSaleTitle,
  isFetching = false,
}) => {
  const hasWareHouseSale =
    hasCollectionSale && isWarehouseSaleCollection && warehouseSaleTitle;

  const { user } = useAuth();

  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`);
  const [isTablet] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isMiniTablet] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isPhone] = useMediaQuery(`(min-width: ${breakpoints.xs})`);
  const productsPerRow = isDesktop
    ? 4
    : isTablet
    ? 3
    : isMiniTablet || isPhone
    ? 2
    : 1; // 1 for fold mobile, 2 for mini tablet or normal phones, 3 for tablet, 4 for desktop
  const itemSize =
    isDesktop || isTablet || isMiniTablet ? 550 : hasWareHouseSale ? 375 : 355;

  const [modifiedProductsData, setModifiedProductsData] = useState(products);
  const router = useRouter();
  const slug = router.asPath;
  const [scrollState, setScrollState] = useLocalStorageQuiet("scrollOffset", {
    [slug]: 0,
  });

  useEffect(() => {
    if (!ssrMode) {
      if (user?.wishlist) {
        setModifiedProductsData(
          uniqueByKey(
            products.map((prod: ProductType) => {
              const inWishlist = user?.wishlist?.edges?.some(
                (wishlistPro) => prod.id === wishlistPro.node.productId
              );
              return { ...prod, inWishlist };
            }),
            "slug"
          )
        );
      } else {
        setModifiedProductsData(products);
      }
    }
  }, [user, products]);

  function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }
  const chunkedProducts = [...chunks(modifiedProductsData, productsPerRow)];

  const isItemLoaded = (index) =>
    !canLoadMore || index < chunkedProducts.length;

  const rowCount =
    chunkedProducts.length + (infiniteScrollingMode && canLoadMore ? 1 : 0);

  const Item = ({ index, style }) => (
    <div style={style}>
      {index >= chunkedProducts.length ? (
        loadMoreError ? (
          <Center padding={10}>
            Sorry, we couldn&apos;t load more items just now
            <Button
              width="150px"
              testingContext="LoadErrorButton"
              onClick={onLoadMore}
            >
              Try again
            </Button>
          </Center>
        ) : (
          <Loader />
        )
      ) : (
        <S.List>
          {chunkedProducts[index].map((product, i) => (
            <ProductTile
              product={product}
              key={product.id}
              priority={index === 0 && i === 0}
              index={index * 2 + i}
              deliveryFilter={deliveryFilter}
              hasCollectionSale={hasCollectionSale}
              isWarehouseSaleCollection={isWarehouseSaleCollection}
              warehouseSaleTitle={warehouseSaleTitle}
            />
          ))}
        </S.List>
      )}
    </div>
  );

  const handleScroll = ({ scrollOffset }) => {
    setScrollState({ ...scrollState, [slug]: scrollOffset });
  };

  return (
    <>
      {isFetching && (
        <>
          <Box height="2000px" />
          <Spinner
            color={accentBlue}
            sx={{
              bottom: 0,
              height: "50px",
              left: 0,
              margin: "auto",
              position: "fixed",
              right: "5px",
              top: "0.875rem",
              width: "50px",
            }}
          />
        </>
      )}

      {!loading && !isFetching && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={numberOfProducts}
          loadMoreItems={infiniteScrollingMode ? onLoadMore : () => {}}
        >
          {({ onItemsRendered }) => (
            <>
              <ReactWindowScroller>
                {({ ref, outerRef, style, onScroll }) => (
                  <FixedSizeList
                    style={style}
                    outerRef={outerRef}
                    itemCount={rowCount}
                    onItemsRendered={onItemsRendered}
                    itemSize={itemSize}
                    ref={ref}
                    initialScrollOffset={scrollState ? scrollState[slug] : 0}
                    height={2000}
                    onScroll={(props) => {
                      handleScroll(props.scrollOffset);
                      onScroll(props);
                    }}
                  >
                    {Item}
                  </FixedSizeList>
                )}
              </ReactWindowScroller>
              {showGoToTop && (
                <GoToTopButton
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                />
              )}
            </>
          )}
        </InfiniteLoader>
      )}

      {!isWarehouseSaleCollection &&
        !loading &&
        !isFetching &&
        !products.length && (
          <Box w="100%" textAlign="center" mt={4}>
            Sorry, no products were found.
          </Box>
        )}

      {hasCollectionSale &&
        isWarehouseSaleCollection &&
        !loading &&
        !isFetching &&
        !products.length && (
          <Box w="100%" textAlign="center" mt={4}>
            Sorry, no products were found.
          </Box>
        )}
      {/* Prevent footer displaying during infinite scroll */}
      {infiniteScrollingMode && <Box height="2000px" />}
    </>
  );
};
