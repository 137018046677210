export function uniqueByKey(
  items: Array<{ [key: string]: any }>,
  key: string
): Array<{ [key: string]: any }> {
  const map = {};
  const result: Array<{ [key: string]: any }> = [];

  for (let i: number = 0; i < items.length; i++) {
    const item = items[i];
    if (typeof item[key] !== "undefined" && !map[item[key]]) {
      map[item[key]] = true;
      result.push(item);
    }
  }

  return result;
}
