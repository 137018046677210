/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import { MenuIcon } from "@components/atoms";
import { useHandlerWhenClickedOutside } from "@hooks/useHandlerWhenClickedOutside";
import { messageshadow, white } from "@styles/variables";

import userImg from "../../../images/profile-new.svg";
import { IProps } from "./types";

export const MenuDropdown: React.FC<IProps> = ({ content, label }) => {
  const [isActive, setIsActive] = useState(false);

  const { setElementRef } = useHandlerWhenClickedOutside(() => {
    setIsActive(false);
  });

  return (
    <Box data-test="userButton" tabIndex={0} m={0} ref={setElementRef()}>
      <Box
        aria-label="toggle profile menu"
        _hover={{
          cursor: "pointer",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={() => setIsActive(!isActive)}
        marginRight={1}
      >
        <Text
          color="black"
          padding="0.35rem 0.1rem"
          textTransform="uppercase"
          textAlign="right"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="90px"
          flex="none"
          lineHeight="inherit"
          marginRight={1}
        >
          {label}
        </Text>
        <MenuIcon
          dataTest="desktopMenuToggleProfileIcon"
          svgSrc={userImg.src}
        />
      </Box>
      <Box onClick={() => setIsActive(false)} position="relative">
        <Box
          display={isActive ? "block" : "none"}
          position="absolute"
          top="3px"
          box-shadow={messageshadow}
          backgroundColor={white}
          zIndex={isActive ? 2 : "initial"}
          boxShadow="0px 6px 15px 3px #00000040;"
        >
          {content}
        </Box>
      </Box>
    </Box>
  );
};
