import { createContext } from "react";

import { ShopConfig } from "@utils/ssr";

export const defaultContext: {
  mainMenu: ShopConfig["mainMenu"];
  footer: ShopConfig["footer"];
} = {
  mainMenu: {
    __typename: "Menu",
    items: [],
  },
  footer: {
    id: "1",
    __typename: "Menu",
    items: [],
  },
};

export const MenuContext = createContext<typeof defaultContext>(defaultContext);

MenuContext.displayName = "MenuContext";
