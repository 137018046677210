import * as React from "react";

import { Message, OverlayContextInterface } from "..";

const NotificationOverlay: React.FC<{
  overlay: OverlayContextInterface;
}> = ({ overlay: { hide, context } }) => (
  <Message title={context.title} status={context.status} onClose={hide}>
    {context.content}
  </Message>
);

export default NotificationOverlay;
