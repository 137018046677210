import { useState } from "react";

function getItem<T>(initialValue, key: string) {
  let result: T | undefined;
  try {
    const item = window.localStorage.getItem(key);
    result = item ? JSON.parse(item) : initialValue;
  } catch {
    result = initialValue;
  }
  return result;
}
function storeValue(valueToStore: any, key: string) {
  try {
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  } catch {
    // eslint-disable-next-line
    console.warn(`Could not save ${key} to localStorage`);
  }
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T | undefined
): [T | undefined, (value: T | undefined) => void] {
  const [storedValue, setStoredValue] = useState<T | undefined>(() =>
    getItem(initialValue, key)
  );

  const setValue = (value: T | undefined) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    storeValue(valueToStore, key);
  };

  return [storedValue, setValue];
}

// Store in local storage only and do not update component state
export function useLocalStorageQuiet<T>(
  key: string,
  initialValue: T | undefined
): [T | undefined, (value: T | undefined) => void] {
  const [storedValue] = useState<T | undefined>(() =>
    getItem(initialValue, key)
  );

  const setValue = (value: T | undefined) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    storeValue(valueToStore, key);
  };

  return [storedValue, setValue];
}
