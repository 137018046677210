import styled from "@emotion/styled";

import {
  accentBlue,
  boldFontWeight,
  gray,
  grayLight,
  spacer,
  white,
} from "@styles/constants";

const Overlay = styled.div`
  background-color: rgba(199, 207, 207, 0.8);
  height: 100%;
  position: fixed;
  top: 0;
  transition: 300ms;
  width: 100%;
  z-index: 10000;

  .search {
    .overlay__right {
      background-color: transparent;
      height: auto;
    }
  }

  .no-background {
    background-color: transparent;
  }

  .left {
    position: fixed;
    min-height: 100%;
    background-color: ${white};
    animation: sidenavLeftOpen 0.3s;
  }

  .right {
    position: fixed;
    background-color: ${white};
    right: 0;
    top: 0;
    height: 100%;
    animation: sidenavRightOpen 0.3s;
  }

  .header {
    align-items: center;
    border-bottom: 1px solid ${grayLight};
    display: flex;
    flex: 1;
    font-weight: ${boldFontWeight};
    height: 3.6rem;
    justify-content: space-between;
    text-transform: uppercase;

    header-text {
      margin: 0 ${spacer}rem;
      text-align: center;

      header-items {
        color: ${gray};
      }
    }

    header__close-icon {
      cursor: pointer;
      margin: 0 ${spacer}rem;

      &:hover,
      &:focus {
        svg {
          * {
            fill: ${accentBlue};
          }
        }
      }
      div {
        display: flex;
      }
    }

    header__cart-icon {
      margin: 0 ${spacer}rem;

      div {
        display: flex;
        svg {
          * {
            fill: ${accentBlue};
          }
        }
      }
    }
  }
`;

export default { Overlay };
