import { Center, SimpleGrid } from "@chakra-ui/react";
import { useAuth } from "@the-volte/svc-core-sdk";
import { NextPage } from "next";
import React from "react";

import { Button, Redirect } from "@components/atoms";
import useLogin from "@hooks/useLogin";
import { paths } from "@paths";

import { CheckoutLoginDiv, InfoP } from "./styles";

const CheckoutLogin: NextPage = () => {
  const { user } = useAuth();
  const { login, register } = useLogin();

  return user ? (
    <Redirect url={paths.checkout} />
  ) : (
    <div className="container">
      <CheckoutLoginDiv>
        <br />
        <Center>
          <h2>Account Required</h2>
        </Center>
        <br />
        <Center>
          <InfoP>You must login to continue and place your order</InfoP>
        </Center>
        <br />
        <SimpleGrid columns={2} spacing={4}>
          <Button testingContext="CheckoutLoginButton" onClick={login}>
            Login
          </Button>
          <Button testingContext="CheckoutLoginButton" onClick={register}>
            Register
          </Button>
        </SimpleGrid>
        <br />
        <h3 onClick={login}>Forgot password?</h3>
        <br />
      </CheckoutLoginDiv>
    </div>
  );
};

export default CheckoutLogin;
