import * as React from "react";

const CostRow: React.FC<{
  mediumScreen: boolean;
  heading: string;
  cost: React.ReactNode;
}> = ({ mediumScreen, heading, cost }) => (
  <tr>
    <td colSpan={mediumScreen ? 4 : 3} className="cost">
      {heading}
    </td>
    <td colSpan={1} style={{ textAlign: "right" }}>
      {cost}
    </td>
  </tr>
);

export default CostRow;
