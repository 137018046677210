import classNames from "classnames";
import * as React from "react";

import { OverlayContextInterface } from "./context";
import S from "./styles";

interface OverlayProps {
  context: OverlayContextInterface;
  className?: string;
  /**
   * Unique name used as selector for writing e2e tests in Cypress	   * Unique name used as selector for writing e2e tests in Cypress
   */
  testingContext: string;
}

const Overlay: React.FC<OverlayProps> = ({
  children,
  className,
  context: { type, theme, hide },
  testingContext,
}) => (
  <S.Overlay
    className={classNames("overlay", {
      [`${type}`]: !!type,
      [className]: !!className,
    })}
    data-test={testingContext}
    onClick={hide}
  >
    <div className={`${theme}`} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  </S.Overlay>
);

export default Overlay;
