export const maybe = <T,>(exp: () => T, d?: T) => {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
};

export type UknownObject<V = any> = { [key: string]: V };

export type NonNullableType<T> = {
  [P in keyof T]-?: NonNullableType<NonNullable<T[P]>>;
};
