import { FormattedMessage } from "@react-intl";
import * as React from "react";

import { commonMessages } from "@temp/intl";

import S from "./styles";
import { FormAddressType } from "./types";

const AddressSummary: React.FC<{
  address?: FormAddressType;
  email?: string;
  forceFirstName?: string;
  paragraphRef?: React.RefObject<HTMLParagraphElement>;
}> = ({ address, forceFirstName = "", email, paragraphRef }) => {
  if (address) {
    return (
      <S.AddressSummary ref={paragraphRef}>
        <strong>{`${forceFirstName || address.firstName} ${
          address.lastName
        }`}</strong>
        <br />
        {address.companyName && (
          <>
            {address.companyName} <br />
          </>
        )}
        {address.streetAddress1}
        <br />
        {address.streetAddress2 && (
          <>
            {address.streetAddress2} <br />
          </>
        )}
        {address.city}, {address.postalCode}
        <br />
        {address.countryArea && (
          <>
            {address.countryArea} <br />
          </>
        )}
        {address.country.country}
        <br />
        {address.phone && (
          <>
            <FormattedMessage
              {...commonMessages.phoneNumber}
              values={{ phone: address.phone }}
            />{" "}
            <br />
          </>
        )}
        {email && (
          <>
            {email} <br />
          </>
        )}
      </S.AddressSummary>
    );
  }
  if (email) {
    return <S.AddressSummary ref={paragraphRef}>{email}</S.AddressSummary>;
  }
  return null;
};

export default AddressSummary;
