import { Box } from "@chakra-ui/react";
import { ProductVariant } from "@the-volte/svc-core-sdk/lib/fragments/gqlTypes/ProductVariant";
import { OrderByToken_orderByToken_lines_unitPrice } from "@the-volte/svc-core-sdk/lib/queries/gqlTypes/OrderByToken";
import { OrdersByUser_me_orders_edges_node_reservations } from "@the-volte/svc-core-sdk/lib/queries/gqlTypes/OrdersByUser";
import { format, isValid } from "date-fns";
import Link from "next/link";
import * as React from "react";
import { generatePath } from "react-router";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { paths } from "@paths";

export type ILine = Omit<
  ProductVariant,
  "__typename" | "sku" | "quantityAvailable"
> & {
  quantity: number;
  totalPrice: OrderByToken_orderByToken_lines_unitPrice;
  quantityAvailable?: number;
};

interface ReadProductRowProps {
  mediumScreen: boolean;
  line: ILine;
  reservation: OrdersByUser_me_orders_edges_node_reservations | null;
}

export interface EditableProductRowProps {
  processing?: boolean;
}

const ProductRow: React.FC<ReadProductRowProps & EditableProductRowProps> = ({
  mediumScreen,
  processing,
  line,
  reservation,
}) => {
  const productUrl = line.product
    ? generatePath(paths.product, { slug: line.product.slug })
    : null;

  const rentalStart = reservation?.rentalStartDate;
  const rentalEnd = reservation?.rentalEndDate;
  const rentalStartDateFormat =
    isValid(new Date(rentalStart)) &&
    format(new Date(rentalStart), "dd LLL yyyy");
  const rentalEndDateFormat =
    isValid(new Date(rentalEnd)) && format(new Date(rentalEnd), "dd LLL yyyy");
  return (
    <tr className={processing ? "row--processing" : undefined}>
      <td>
        {productUrl ? (
          <div className="product-row">
            {mediumScreen && (
              <Link href={productUrl}>
                <a>
                  <div className="thumbnail">
                    <Thumbnail
                      heightPixels={50}
                      widthPixels={50}
                      source={line.product}
                    />
                  </div>
                </a>
              </Link>
            )}
            <Link href={productUrl}>
              <a>{line.product.name}</a>
            </Link>
          </div>
        ) : (
          line.name
        )}
      </td>

      {mediumScreen && (
        <td>
          <TaxedMoney taxedMoney={line.totalPrice} />
        </td>
      )}

      <td>
        {line.attributes &&
          line.attributes.map(({ attribute, values }) =>
            attribute?.name === "Rental Period" &&
            rentalStartDateFormat &&
            rentalEndDateFormat ? (
              <Box key={attribute.id} w={["6rem", "auto"]}>
                <Box as="p">
                  {attribute.name}:{" "}
                  {values.map((value) => value?.name).join(", ")}
                </Box>
                <Box mt={["1rem", "0"]} display={["block", "flex"]}>
                  Start:{" "}
                  <Box ml={["0", "0.3rem"]}> {rentalStartDateFormat}</Box>
                </Box>
                <Box mt={["1rem", "0"]} display={["block", "flex"]}>
                  End: <Box ml={["0", "0.3rem"]}> {rentalEndDateFormat}</Box>
                </Box>
              </Box>
            ) : (
              <p key={attribute.id}>
                {attribute.name}:{" "}
                {values.map((value) => value?.name).join(", ")}
              </p>
            )
          )}
      </td>

      <td className="quantity-cell">
        <p>{line.quantity}</p>
      </td>

      <td colSpan={2} style={{ textAlign: "right" }}>
        <TaxedMoney taxedMoney={line.totalPrice} />
      </td>
    </tr>
  );
};

export default ProductRow;
