import styled from "@emotion/styled";

const crumbStyle = `
  display: inline-flex;
  flex-wrap: wrap;
`;

const CrumbsList = styled.ul`
  ${crumbStyle}
`;
const CrumbsDiv = styled.div`
  margin-top: 1rem;
  ${crumbStyle}
`;

const BackLink = styled.button`
  color: ${(props) => props.theme.colors.legacy.grayMedium};
  border-style: none;
  transition: 0.3s;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.primary.base};
  }
`;

const Li = styled.li`
  margin-top: 1rem;
  &:not(:last-of-type) {
    &:after {
      content: url("../../../../images/breadcrumbs-arrow.svg");
      display: inline-block;
      margin: 0 ${(props) => parseInt(props.theme.spacing.spacer, 10) / 2}rem;
    }
  }

  a {
    color: ${(props) => props.theme.colors.legacy.grayMedium};
    vertical-align: middle;
    transition: 0.3s;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.primary.base};
    }
  }

  &.active {
    a {
      color: ${(props) => props.theme.colors.legacy.grayMedium};

      &:hover {
        color: ${(props) => props.theme.colors.legacy.grayDark};
      }
    }
  }
`;

export default { CrumbsList, CrumbsDiv, Li, BackLink };
