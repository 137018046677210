import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CheckoutLoginDiv = styled.div`
  ${({ theme }) => css`
    h3 {
      text-align: center;
      font-size: ${theme.checkoutTitle.fontSize};
      padding-top: 0.5rem;
      font-weight: 400;
    }

    h2 {
      text-align: center;
      font-size: ${theme.pageTitle.fontSize};
      padding-top: 0.5rem;
      font-weight: 400;
    }
  `}
`;

export const InfoP = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.infoText.fontSize};
  `}
`;
