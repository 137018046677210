import dynamic from "next/dynamic";

// export all component to dynamic with then

export const AddToCartSection = dynamic(() =>
  import("./AddToCartSection").then((mod) => mod.AddToCartSection)
);
export const AddToCartSectionDesignB = dynamic(() =>
  import("./AddToCartSection").then((mod) => mod.AddToCartSectionDesignB)
);
export const AddressForm = dynamic(() =>
  import("./AddressForm").then((mod) => mod.AddressForm)
);
export const AddressFormModal = dynamic(() =>
  import("./AddressFormModal").then((mod) => mod.AddressFormModal)
);
export const AddressGrid = dynamic(() =>
  import("./AddressGrid").then((mod) => mod.AddressGrid)
);
export const AddressGridSelector = dynamic(() =>
  import("./AddressGridSelector").then((mod) => mod.AddressGridSelector)
);
export const CartRow = dynamic(() =>
  import("./CartRow").then((mod) => mod.CartRow)
);
export const CartSidebar = dynamic(() =>
  import("./CartSidebar").then((mod) => mod.CartSidebar)
);
export const CartSummary = dynamic(() =>
  import("./CartSummary").then((mod) => mod.CartSummary)
);
export const CheckoutAddress = dynamic(() =>
  import("./CheckoutAddress").then((mod) => mod.CheckoutAddress)
);
export const CheckoutPayment = dynamic(() =>
  import("./CheckoutPayment").then((mod) => mod.CheckoutPayment)
);
export const CheckoutReview = dynamic(() =>
  import("./CheckoutReview").then((mod) => mod.CheckoutReview)
);
export const CheckoutShipping = dynamic(() =>
  import("./CheckoutShipping").then((mod) => mod.CheckoutShipping)
);
export const CollectionGrid = dynamic(() =>
  import("./CollectionGrid").then((mod) => mod.CollectionGrid)
);
export const DiscountForm = dynamic(() =>
  import("./DiscountForm").then((mod) => mod.DiscountForm)
);
export const DummyPaymentGateway = dynamic(() =>
  import("./DummyPaymentGateway").then((mod) => mod.DummyPaymentGateway)
);
export const FilterSidebar = dynamic(() =>
  import("./FilterSidebar").then((mod) => mod.FilterSidebar)
);
export const InputDatePicker = dynamic(() =>
  import("./InputDatePicker").then((mod) => mod.InputDatePicker)
);
export const DatePicker = dynamic(() =>
  import("./InputDatePicker/DatePicker/DatePicker").then(
    (mod) => mod.DatePicker
  )
);
export const MobileNav = dynamic(() =>
  import("./MobileNav").then((mod) => mod.MobileNav)
);
export const MobileNavMainMenu = dynamic(() =>
  import("./MobileNavMainMenu").then((mod) => mod.MobileNavMainMenu)
);
export const Modal = dynamic(() => import("./Modal").then((mod) => mod.Modal));
export const Overlay = dynamic(() =>
  import("./Overlay").then((mod) => mod.Overlay)
);
export const PaymentGatewaysList = dynamic(() =>
  import("./PaymentGatewaysList").then((mod) => mod.PaymentGatewaysList)
);

export const ProductGallery = dynamic(() =>
  import("./ProductGallery").then((mod) => mod.ProductGallery)
);

export const ProductListHeader = dynamic(() =>
  import("./ProductListHeader").then((mod) => mod.ProductListHeader)
);

export const StripePaymentGateway = dynamic(() =>
  import("./StripePaymentGateway").then((mod) => mod.StripePaymentGateway)
);
export const ThankYou = dynamic(() =>
  import("./ThankYou").then((mod) => mod.ThankYou)
);
export const FeaturedDesigners = dynamic(() =>
  import("./FeaturedDesigners").then((mod) => mod.FeaturedDesigners)
);
export const AsSeenIn = dynamic(() =>
  import("./AsSeenIn").then((mod) => mod.AsSeenIn)
);
export const SeamlessLending = dynamic(() =>
  import("./SeamlessLending").then((mod) => mod.SeamlessLending)
);
export const CircularPartners = dynamic(() =>
  import("./CircularPartners").then((mod) => mod.CircularPartners)
);

export const Carousel = dynamic(() =>
  import("./Carousel").then((mod) => mod.Carousel)
);

export const ContactDetails = dynamic(() =>
  import("./ContactDetails").then((mod) => mod.ContactDetails)
);

export { Footer } from "./Footer";
export { ProductList } from "./ProductList";
