import styled from "@emotion/styled";

export const Button = styled.button`
  @media (max-width: 540px) {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    box-shadow: rgba(36, 36, 36, 0.15) 0px 20px 30px 0px;
  }
  display: none;
`;
