import * as React from "react";

import { HomePageProducts_marketplaces } from "@app/views/Home/gqlTypes/HomePageProducts";

import { META_DEFAULTS } from "../../../core/config";

type MetaProps = JSX.IntrinsicElements["meta"];

export interface MetaContextInterface {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  type?: string;
  custom?: MetaProps[];
  canonicalUrl?: string;
  language?: string;
  marketplace?: boolean;
  domainUrl?: string;
  marketplaces?: (HomePageProducts_marketplaces | null)[] | null;
}

export const { Provider, Consumer } =
  React.createContext<MetaContextInterface>(META_DEFAULTS);
