import styled from "@emotion/styled";

const AddressSummary = styled.p`
  color: ${(props) => props.theme.colors.grayDark};
  line-height: 1.5;
  font-size: ${(props) => props.theme.typography.smallFontSize};;

  padding-top: 1.5rem;
  padding-bottom: 2rem;

  strong {
    font-weight: ${(props) => props.theme.typography.boldFontWeight};;
    display: inline-block;
    padding-bottom: ${(props) => props.theme.spacing.spacer}; * 0.5;
  }
`;

export default { AddressSummary };
