import { FormattedMessage } from "@react-intl";
import classNames from "classnames";
import Link from "next/link";
import { NextRouter, withRouter } from "next/router";
import * as React from "react";
import Media from "react-media";

import { ssrMode } from "@app/constants";
import { paths } from "@paths";
import { commonMessages } from "@temp/intl";

import { smallScreen } from "../../../globalStyles/variables";
import S from "./styles";

export interface Breadcrumb {
  value: string;
  link: string;
}

const Breadcrumbs: React.FC<{
  breadcrumbs: Breadcrumb[];
  router: NextRouter;
}> = ({ breadcrumbs, router }) => (
  <Media
    query={{
      minWidth: smallScreen,
    }}
  >
    {(matches) =>
      matches ? (
        <S.CrumbsList>
          <S.Li>
            <Link prefetch={false} href={paths.home}>
              <a>
                <FormattedMessage {...commonMessages.home} />
              </a>
            </Link>
          </S.Li>
          {breadcrumbs.map((breadcrumb, index) => (
            <S.Li
              key={`${breadcrumb.value}-${index}`}
              className={classNames({
                active: index === breadcrumbs.length - 1,
              })}
            >
              <Link prefetch={false} href={breadcrumb.link} scroll={false}>
                <a>{breadcrumb.value}</a>
              </Link>
            </S.Li>
          ))}
        </S.CrumbsList>
      ) : (
        !ssrMode && (
          <S.CrumbsDiv>
            <S.BackLink onClick={() => router.back()}>
              <FormattedMessage defaultMessage="Back" />
            </S.BackLink>
          </S.CrumbsDiv>
        )
      )
    }
  </Media>
);

export default withRouter(Breadcrumbs);
