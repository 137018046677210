import { Box, ScaleFade, Spinner } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { ReactSVG } from "react-svg";

import { klaviyoPublicKey, klaviyoSubscribeListId } from "@app/constants";
import { commonMessages } from "@app/intl";

import arrowImg from "images/arrow-right.svg";

const steps = [
  {
    name: "email",
    placeholder: commonMessages.enterEmail.defaultMessage,
  },
  {
    name: "firstname",
    placeholder: commonMessages.enterFirstName.defaultMessage,
  },
  {
    name: "lastname",
    placeholder: commonMessages.enterLastName.defaultMessage,
  },
];

const handleAddToKlavyoList = async (email, handleSuccessCallback) => {
  try {
    const url = `https://a.klaviyo.com/client/subscriptions/?company_id=${klaviyoPublicKey}`;
    const options = {
      method: "POST",
      headers: { revision: "2023-06-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            list_id: klaviyoSubscribeListId,
            custom_source: "Homepage footer signup form",
            email,
          },
        },
      }),
    };
    await fetch(url, options);
    handleSuccessCallback();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

const handleUpdateKalviyoProfile = async (
  email,
  firstName,
  lastName,
  handleSuccessCallback
) => {
  try {
    const url = `https://a.klaviyo.com/client/profiles/?company_id=${klaviyoPublicKey}`;
    const options = {
      method: "POST",
      headers: { revision: "2023-06-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "profile",
          attributes: {
            email,
            first_name: firstName,
            last_name: lastName,
          },
        },
      }),
    };
    await fetch(url, options);
    handleSuccessCallback();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
const handleKeyUp = (
  e: React.KeyboardEvent<HTMLInputElement>,
  handler: () => void
) => {
  if (e.key === "Enter") {
    handler();
  }
};
const KlaviyoSubscriber = () => {
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const handleNextStep = async () => {
    const handleSuccessCallback = () => {
      if (step >= 0 && step < 3) {
        setStep((_step) => _step + 1);
      }
    };

    setLoading(true);
    if (step === 0) {
      if (inputRef?.current?.checkValidity()) {
        setError("");
        // Create the profile, then add to the list
        // Subscriber will get a confirmation email before being added to the list
        await handleUpdateKalviyoProfile(email, undefined, undefined, () =>
          handleAddToKlavyoList(email, handleSuccessCallback)
        );
      } else {
        setError("Invalid email");
      }
    } else {
      handleUpdateKalviyoProfile(
        email,
        firstName,
        lastName,
        handleSuccessCallback
      );
    }
    setLoading(false);
  };

  return (
    <Box bgColor="tertiary" w="full">
      <Box mx="auto">
        <Box fontSize="1.4rem" lineHeight="1.5rem">
          STAY IN THE KNOW ON THE LATEST STYLES
        </Box>
        <Box display="flex" mx="auto" mt="1rem">
          {step !== 3 ? (
            <Box
              borderBottom="1px dashed white"
              display="flex"
              justifyContent="space-between"
              py="0.5rem"
              fontSize="1.2rem"
              mx={loading ? "auto" : "none"}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <ScaleFade key={step} initialScale={0.6} in>
                    <Box
                      ref={inputRef}
                      as="input"
                      type={step === 0 ? "email" : "text"}
                      autoFocus={step !== 0}
                      placeholder={steps[step]?.placeholder}
                      bgColor="tertiary"
                      color="white"
                      outline="none"
                      _placeholder={{ color: "white" }}
                      onKeyUp={(e) => handleKeyUp(e, handleNextStep)}
                      onChange={(e) => {
                        if (step === 0) {
                          setEmail(e.target.value);
                          return;
                        }
                        if (step === 1) {
                          setFirstName(e.target.value);
                          return;
                        }
                        setLastName(e.target.value);
                      }}
                    />
                  </ScaleFade>
                  <Box
                    as="button"
                    pl="1rem"
                    display="inline"
                    onClick={handleNextStep}
                  >
                    <Box as="button" onClick={handleNextStep}>
                      <ReactSVG src={arrowImg.src} />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <ScaleFade initialScale={0.6} in>
              {commonMessages.thankYouSubscribe.defaultMessage}
            </ScaleFade>
          )}
        </Box>
        <Box textAlign="left" mt="0.5rem" color="red">
          {error}
        </Box>
      </Box>
    </Box>
  );
};

export default KlaviyoSubscriber;
